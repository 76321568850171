<template>
  <b-row class="mt-5">
    <b-col>
      <b-card title="Tipos de vehículos">
        <div class="d-flex justify-content-between">
          <div>
            <button
              @click="loadVehiclesType()"
              class="btn btn-sm btn-circle btn-outline-primary mb-2 mr-3"
            >
              <i class="flaticon-refresh"></i>
            </button>
            <button
              class="btn btn-sm btn-circle btn-primary mb-2"
              v-if="can('create-vehicle-type')"
              @click="
                () => {
                  $refs.CVT.init();
                }
              "
            >
              <i class="flaticon2-lorry"></i> Agregar
            </button>
          </div>
        </div>
        <transition name="fade">
          <b-row v-if="items.length">
            <b-col class="dx-viewport">
              <DxDataGrid
                id="TurnsTable"
                :data-source="items"
                :show-borders="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="true"
                :columnAutoWidth="true"
                :allow-column-resizing="true"
              >
                <DxExport :enabled="true" />
                <DxGroupPanel
                  :visible="true"
                  emptyPanelText="Arrastre una columna aquí para agrupar"
                />
                <DxSearchPanel
                  :visible="true"
                  :width="240"
                  placeholder="Buscar..."
                />
                <DxPaging :page-size="15" />
                <DxPager
                  :visible="true"
                  infoText="Página {0} de {1} ({2} registros)"
                  :allowed-page-sizes="pageSizes"
                  display-mode="full"
                  :show-page-size-selector="true"
                  :show-info="true"
                  :show-navigation-buttons="true"
                />
                <DxHeaderFilter :visible="true" />
                <DxFilterRow :visible="true">
                  <DxOperationDescriptions
                    equal="Igual a"
                    notEqual="Diferente de"
                    startsWith="Empieza con"
                    endsWith="Termina con"
                    contains="Contiene"
                    between="Entre"
                    notContains="No contiene"
                    lessThanOrEqual="Menor o igual"
                    greaterThanOrEqual="Mayor o igual"
                    lessThan="Menor que"
                    greaterThan="Mayor que"
                  />
                </DxFilterRow>
                <DxColumn
                  data-field="id_tipovehiculo"
                  caption="Cod"
                  alignment="center"
                  width="80"
                />
                <DxColumn
                  data-field="descripcion"
                  caption="Marca"
                  alignment="center"
                />
                <DxColumn type="buttons" cell-template="templateButtons" />

                <template #templateButtons="{ data: cellData }">
                  <div>
                    <router-link
                      v-if="can('update-vehicle-type')"
                      :to="{
                        name: 'updateVehicleType',
                        params: { id: cellData.data.id_tipovehiculo },
                      }"
                      class="btn btn-icon btn-circle btn-sm"
                    >
                      <b><i class="flaticon-edit-1 text-edit"></i></b>
                    </router-link>
                    <button
                      class="btn btn-circle btn-sm"
                      v-if="can('delete-vehicle-type')"
                      @click="deleteVehicleType(cellData.data.id_tipovehiculo)"
                    >
                      <i class="la la-trash text-danger"></i>
                    </button>
                  </div>
                </template>
              </DxDataGrid>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <div v-if="!loading">
                <div class="text-center">
                  <img src="assets/img/empty.png" alt="Empty" />
                  <p class="text-muted">No hay información para mostrar</p>
                </div>
              </div>
              <div class="text-center" v-else>
                <img src="assets/img/loader.gif" alt="Loading..." />
              </div>
            </b-col>
          </b-row>
        </transition>
      </b-card>
    </b-col>
    <CreateVehicleType ref="CVT" @loadVehiclesType="loadVehiclesType()" />
  </b-row>
</template>
<style>
.action:hover i {
  opacity: 0.7 !important;
}

#TurnsTable {
  max-height: 700px !important;
}
</style>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
  DxExport,
  DxGroupPanel,
} from "devextreme-vue/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import axios from "axios";
import can from "@/permission";
import { mapGetters } from "vuex";
import CreateVehicleType from "./CreateVehicleType.vue";
// import _ from "lodash";
export default {
  name: "user-component",
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxOperationDescriptions,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxExport,
    DxGroupPanel,
    CreateVehicleType,
  },
  data() {
    return {
      items: [],
      loading: false,
      pageSizes: [5, 10, 15, 30, 50],
      texts: {
        editRow: "Editar",
        saveRowChanges: "Guardar",
        saveAllChanges: "Guardar cambios",
        deleteRow: "Eliminar",
        cancelRowChanges: "Cancelar",
        confirmDeleteTitle: "Información",
        confirmDeleteMessage: "¿Realmente desea eliminar el registro?",
        cancelAllChanges: "Descartar cambios",
      },
    };
  },
  mounted() {
    this.loadVehiclesType();
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    can: can,
    deleteVehicleType(id) {
      this.$swal
        .fire({
          title: "Información",
          text: "¿Quiere eliminar el tipo de vehículo?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`/vehiculo-tipos/${id}`)
              .then((res) => {
                if (res.data.message == "success") {
                  this.$swal.fire(
                    "Información!",
                    res.data.information,
                    "success"
                  );
                  this.loadVehiclesType();
                } else {
                  this.$swal.fire(
                    "Información!",
                    res.data.information,
                    "warning"
                  );
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
    },
    loadVehiclesType() {
      this.items = [];
      this.loading = true;
      axios
        .get("/vehiculo-tipos")
        .then((res) => {
          this.items = res.data;
          console.table(this.items);
          //   this.items = _.uniqBy(this.items, "id_vehiculo");
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Information");

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Document.xlsx"
          );
        });
      });
      e.cancel = true;
    },
  },
};
</script>
